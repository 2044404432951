import { useCallback, useEffect, useState } from 'react';
import { StatusTrackingInfo } from '../../interfaces/StatusTrackingInterface';
import { endpoints } from '../../utils/URLs';
import axios from 'axios';
import LoaderWithText from '../common/LoaderWithText';
import NotFound from '../common/NotFound';
import TrackingBoard from '../tracking/TrackingBoard';
import { IFulfilmentResponse } from '../../interfaces/FulfilmentInterfaces';

const RequestStatusTrackingSidebar = ({
  modalData,
  showModal,
  close,
}: {
  modalData: IFulfilmentResponse | undefined;
  showModal: boolean;
  close: Function;
}) => {
  const [trackingData, setTrackingData] = useState<StatusTrackingInfo | undefined>();
  const [loading, setLoading] = useState(true);

  const getTrackingInfo = useCallback(async () => {
    setTrackingData(undefined);
    const url = `${endpoints.trackingInfo.mainUrl}/request/${modalData?.requestId}`;
    setLoading(true);
    try {
      const response = await axios.get(url);
      setTrackingData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [modalData]);

  useEffect(() => {
    if (showModal) {
      getTrackingInfo();
    }
  }, [getTrackingInfo, modalData, showModal]);

  return (
    <div
      id='sidebarMenu-3'
      className={
        showModal
          ? 'col-md-6 col-lg-4 right-sidebar sideshow'
          : 'col-md-6 col-lg-4 right-sidebar sidehide'
      }
    >
      <div className='right-sidebar-sticky pt-3 mt-2'>
        <div>
          <span
            className='btn btn-sm fs-7 text-danger float-right'
            style={{ marginLeft: '-10px' }}
            onClick={() => close('Tracker')}
          >
            Close (x)
          </span>
        </div>
        <div className='mt-5 mb-5'>
          <div className='row justify-content-center'>
            {loading && <LoaderWithText />}
            {!loading && !trackingData && (
              <NotFound notFoundText={`No tracking information found for this request`} />
            )}
            {!loading && trackingData && (
              <div className='col-lg-12 card tracking-card'>
                <h5 className='text-center fw-bold'>
                  Fulfilment Tracking for {trackingData.patientName} ({trackingData.hmoId} -
                  {trackingData.hmoName})
                </h5>
                <div className='mt-4'>
                  {trackingData?.trackingInfo.length > 0 ? (
                    trackingData?.trackingInfo.map((tracking, key) => (
                      <TrackingBoard
                        trackingInfo={tracking}
                        statusRank={trackingData.trackingInfo.length - key}
                      />
                    ))
                  ) : (
                    <div className='text-center'>No tracking information yet</div>
                  )}
                </div>
                <div className='mt-3 ml-3'>
                  For any escalations, {trackingData.supportName} will be happy to help (
                  {trackingData.supportPhone})
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestStatusTrackingSidebar;
