import { useCallback, useEffect, useState } from 'react';
import HeaderLogo from '../dashboard/HeaderLogo';
import TrackingBoard from './TrackingBoard';
import { StatusTrackingInfo } from '../../interfaces/StatusTrackingInterface';
import { endpoints } from '../../utils/URLs';
import axios from 'axios';
import LoaderWithText from '../common/LoaderWithText';
import NotFound from '../common/NotFound';

const FulfilmentTracking = () => {
  const [trackingData, setTrackingData] = useState<StatusTrackingInfo | undefined>();
  const [loading, setLoading] = useState(true);

  const searchParam = new URLSearchParams(window.location.search);
  const trackingCode = searchParam.get('trackingCode');

  const getTrackingInfo = useCallback(async () => {
    const url = `${endpoints.trackingInfo.mainUrl}/${trackingCode}`;
    setLoading(true);
    try {
      const response = await axios.get(url);
      setTrackingData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [trackingCode]);

  useEffect(() => {
    getTrackingInfo();
  }, [getTrackingInfo]);

  return (
    <section>
      <HeaderLogo />
      <div className='container mt-5 mb-5'>
        <div className='row justify-content-center'>
          {loading && <LoaderWithText />}
          {!loading && !trackingData && (
            <NotFound notFoundText={`No data found for the tracking code ${trackingCode}`} />
          )}
          {!loading && trackingData && (
            <div className='col-lg-6 col-md-8 card tracking-card'>
              <h5 className='text-center fw-bold'>
                Fulfilment Tracking for {trackingData.patientName} ({trackingData.hmoId} -
                {trackingData.hmoName})
              </h5>
              <div className='mt-4'>
                {trackingData?.trackingInfo.length > 0 ? (
                  trackingData?.trackingInfo.map((tracking, key) => (
                    <TrackingBoard
                      trackingInfo={tracking}
                      statusRank={trackingData.trackingInfo.length - key}
                    />
                  ))
                ) : (
                  <div className='text-center'>No tracking information yet</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FulfilmentTracking;
